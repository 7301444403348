
.details-box h3 {
	padding-left: 0!important;
	padding-right: 0!important;
	padding-top: 0!important;
	font-family: inherit!important;
	color: inherit!important;
	font-size:24px!important;
}

.do-invite {
	line-height: 2!important;
	cursor: pointer!important;
}


#bg-loading{
	background-color: #000;
	height: 100%;
	left: 0;
	opacity: .8;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9998;
	display:none;
}

#loading{
	position:fixed;
	top: calc(50% - 140px);
	width: 100%;
	max-width: 562px;
	max-height: 270px;
	height: 100%;
	background-color: #f6f6f6 !important;
	text-align: center;
	padding: 0;
	z-index: 9999;
	display: none;
}

#loading .brand-logo,
#loading .load{
	display:block;
	text-align: center;
	clear:both;
}

#loading .brand-logo{
	padding-bottom: 20px;
}

#loading .brand-logo img {
	border:3px solid black;
	width: 50px;
}


#loading .load{
	color: #5b5b5b;
	text-transform: uppercase;
	font-size: 18px;
	border-top: 1px solid #6f7c85;
	border-bottom: 1px solid #6f7c85;
	padding: 0px;
}

#loading .load img{
	height: 100%;
	width: 100%;
}

@media(max-width: 769px){
	#loading {
		top: 10%;
		width: 100%;
		padding: 0;
		height: initial;
	}

	#loading .brand-logo img{
		width: 80%;
		max-width: 75px;
	}

	.navbar-toggle{
		padding: 0 !important;
	}
}

@media(min-width: 768px){
	#loading {
		left: calc(50% - 281px);
	}
}



@media (min-width: 320px) and (max-width:767px) {
	#loading {
		left: 0!important;
		right: 0!important;
	}
	.navbar-header {
		float: none;
	}
	.navbar-toggle {
		display: block;
	}
	.navbar-collapse {
		border-top: 1px solid transparent;
		box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
	}

	.navbar-collapse.collapse {
		display: none!important;
	}
	.navbar-collapse.collapse.in{
		display: block!important;
	}
	.navbar-nav {
		float: none!important;
		margin: 7.5px -15px;
	}
	.navbar-nav>li {
		float: none;
	}
	.navbar-nav>li>a {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

@font-face {
	font-family: Poppins-Bold;
	src: url(../fonts/Poppins-Bold.ttf);
}

@font-face {
	font-family: Poppins-Light;
	src: url(../fonts/Poppins-Light.ttf);
}

@font-face {
	font-family: Poppins-Medium;
	src: url(../fonts/Poppins-Medium.ttf);
}

@font-face {
	font-family: Poppins-Regular;
	src: url(../fonts/Poppins-Regular.ttf);
}

@font-face {
	font-family: Poppins-SemiBold;
	src: url(../fonts/Poppins-SemiBold.ttf);
}

body.full header{
	display: none;
}

header.top{
	position:fixed;
	border-bottom:1px solid white;
	background-color:#111111;
	z-index: 3;
}
header, footer {
	display:block;
}
* {
	font-family: 'Poppins-Regular', sans-serif;
	font-size: 16px;
	line-height: 1.6;

}

#background-beach,
#background-profile{
	background-image: url('images/beach-relax-filtered.jpg');
	height: 500px;;
	background-position: center center;
	background-size: cover;
	display:block;
	color:white;
	font-size:30px!important;
}

#background-profile {
	background-image: url('images/beach-rocks.jpg');
}


a:hover,
a:link,
a:visited
a:active {
	text-decoration: none;
	cursor:pointer;
}
body {
	color: #6a6a6a;
}

p{
	font-size: 14px;
	line-height: 1.8;
}

header {
	background-color: transparent;
	color: #fff;
	padding: 10px 0px;
	position: absolute;
	display: block;
	width: 100%;
}

header a.main-logo{
	position: absolute;
	top: 28px;
	left: 0;
	right: 0;
}

header a.main-logo span {
	background-color: white;
	border: 4px solid black;
	padding: 20px;
	display:inline-block;
}

header a.main-logo img {
	max-width:90px;
}

header a {
	color: #ffffff;
}
header a:hover {
	color: #5b5b5b;
}


#register, #login {
	padding-right: 40px;
	background-repeat: no-repeat;
	background-position: calc(100% - 15px) center;
	background-size:20px;
	text-transform: uppercase;
}

#register{
	background-image: url('images/check-icon.png')!important;
	border-right: 1px solid white;
}

#login{
	background-image: url('images/login-icon-white.png')!important;
	padding-right: 45px;
}

#register{
	background-image: url('images/user-icon.png');
	padding-right: 45px;
}

#beach-background {
	background-image: url('images/beach-relax-background.jpg');
	display: block;
	height: 560px;
	background-size: cover;
	background-position: center;
}

.description h2, .description {
	color: white;
}
.description h2 {
	padding:0;
	font-size: 80px;
	margin:10px;
}

.description {
	top: 30%;
	font-size: 30px;
	text-align: center;
	position: relative;
	bottom: 10%;
}

.description hr {
	width: 300px;
	margin-bottom: 20px;
}

hr {
	width: 50px;
	background-color: #DADADA;
	padding-top: 1px;
	margin-bottom: 30px;
	margin-top: 0;
	box-shadow: none;

}
.beige-belt,
.white-belt,
.gray-belt {
	padding: 25px;
	font-size:18px;
}

.beige-belt h2,
.white-belt h2,
.gray-belt h2 {
	font-size: 36px;
	margin-bottom: 30px;
}

.gray-belt hr{
	background-color:#6d6c6c;
	border:none;
	margin-bottom: 30px;
}

.white-belt {
	padding: 60px 10px;
}

.options a{
	color: #5b5b5b;
	text-transform: uppercase;
	font-weight: 600;
	font-family: 'Poppins-Regular', sans-serif;
}
.beige-belt {
	background-color: #f6f6f6;
	font-size: 22px;
	color: #5b5b5b;
	font-family: 'Poppins-Light',sans-serif;
	padding: 20px 25px 15px 25px;
}

.gray-belt {
	background-color: #e0e0e0;
}

h3 {
	font-family: 'Poppins-Light', sans-serif;
	padding: 15px 10px;
	font-size:20px;
	color: #1b1b1b;
}

.program-prices h2{
	font-size: 22px;
}

h2 {
	font-family: 'Poppins-Regular', sans-serif;
	font-weight: 600;
	font-size:44px;
	color: #5b5b5b;
	margin-bottom: 50px;
	text-transform: uppercase;
}

hr {
	width: 50px;
	background-color:#DADADA;
	padding-top: 1px;
	margin-bottom: 60px;
	box-shadow: none;
}
.program-options {
	padding-top:85px;
	padding-bottom:80px;
}
.program-options h2{
	font-size: 28px;
	margin-bottom: 0px;
	font-family: 'Poppins-Regular', sans-serif;
}

.program {
	margin: 30px 0;
}

@media (max-width: 768px){
.program {
	margin: 75% 0px 30px 0;
}
}

.program > div {
	padding-left:0;
	padding-right:0;
}
.program .title {
	color: black;
	font-size: 20px;
	font-family: 'Poppins-Light', sans-serif;;
}
.program .price {
	color: black;
	font-size: 22px;
}
footer {
	background-color:#111111;
	color: #cfcfcf;
	padding: 25px;
	font-family: 'Poppins-Light', sans-serif;
}


footer a,
footer a:hover,
footer a:visited,
footer a:link,
footer a:active {
	color:inherit;
}

footer a:hover{
	text-decoration: underline;
}

footer ul{
	list-style: none;
}

footer ul li {
	/* display: inline-block; */
	margin-left: 2rem;
}

footer ul li:before {
	content: '\00B7\00a0';
}

footer div.left {
	text-align: left;
}

footer div.right {
	text-align: right;
}

.bordered {
	background-color: #f6f6f6;
	padding: 20px;
	margin-top:5px;
}

#register-modal .modal-dialog,
#message .modal-dialog{
	width: 750px;
}
#register-modal .title{
	background-image: url('images/register-icon-gray.png');
	background-repeat: no-repeat;
	background-size: 22px;
	background-position: calc(80%) top;
	padding-right:10%;
}

#login-modal .title{
	background-image: url('../images/user-icon-gray.png');
	background-repeat: no-repeat;
	background-size: 22px;
	background-position: calc(80%) top;
	padding-right:initial;
	color: #5b5b5b;
}

*[data-role="submit"],
input[type="submit"],
button.btn-payment,
.btn-cancel,
.btn-continue{
	color: white;
	background-color: #5b5b5b!important;
	border-radius: 2px;
	padding: 10px;
	margin-top:20px;
	cursor:pointer;
	border:none;
	width:100%;
}

.btn-cancel {
	background-color: #bfbfbf!important;
}

.btn-continue,
.btn-continue:hover{
	background-color: #5b5b5b!important;
	color: white!important;
	display: block;
	text-transform: unset!important;
	font-size: inherit!important;
}

button.btn-payment {
	width: calc(100% - 20px);
}
*[data-role="change"]:hover,
*[data-role="change"]:link,
*[data-role="change"]:active,
*[data-role="change"]:visited{
	text-transform: uppercase;
	color:#5b5b5b;
	font-size:14px;
	text-decoration: underline!important;
}
a.do-fb:hover,
a.do-fb:link,
a.do-fb:active,
a.do-fb:visited {
	color: white;
	padding: 10px 40px 10px 10px;
	text-transform: none;
	background-color: #3b5998;
	width: 100%;
	display: block;
	font-size: 18px;
	border-radius: 2px;
	background-repeat: no-repeat;
	background-image: url(images/facebook.png);
	background-position: 95% center;
}

.modal-body {
	color: white;
}

.form-control {
	border-radius: 0;
	text-align: center;
	color: #5b5b5b;
	font-size: 16px;
}
#contact-us {
	padding-top:80px;
	padding-bottom:110px;
}

#contact-us hr.bold {
	margin:60px 0 30px 0;
	width: 100%;
}


#contact-us hr.vertical {
	height: -webkit-fill-available;
	display: block;
	padding: 100% 0;
	vertical-align: middle;
	margin: 0;
	width: 1px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 50%;
	max-height: 210px;
}
#contact-us h3 {
	font-size: 24px;
	margin-bottom: 0;
	color: #5b5b5b;
	font-family: 'Poppins-SemiBold',sans-serif;
	padding: 30px 0 20px 0;
}

#contact-us .contact-numbers {
	font-weight: 600;
}

#contact-us div.name {
	margin-top: 80px;
}

#contact-us span{
	font-size: 22px;
	font-family: "Poppins-Light";
}

#contact-us .form-control {
	text-align: left;
}

.border-left {
	border-left: 1px solid gray!important;
	padding-left: 40px;
}
textarea.form-control {
	height:250px;
}
.form-control{
	margin-top:10px;
}
.form-control:not(:last-child){
	margin-top:10px;
}

.nopadding {
	padding: 0;
}

.close{
	opacity: 0.8;
}
.close span {
	display:block;
	background-image: url('images/exit.png');
	background-size: cover;
	background-repeat: no-repeat;
	width: 28px;
	height: 28px;
}

::-webkit-input-placeholder {
	color: #5b5b5b!important;
}

:-moz-placeholder { /* Firefox 18- */
	color: #5b5b5b!important;
}

::-moz-placeholder {  /* Firefox 19+ */
	color: #5b5b5b!important;
}

:-ms-input-placeholder {
	color: #5b5b5b!important;
}

.reset, .full{
	background-color: #f6f6f6;
}

.reset div[data-role="response"]{
	margin-top: 5%;
}

.full footer {
	position:absolute;
	bottom:0;
	width:100%;
}

/*Select currency and language*/
.form-control.currency,
.form-control.language {
	border-radius: 0; -webkit-appearance: none;text-align:center;-moz-appearance: none;
}

#message form {
	color: #5b5b5b;
}

.modal-backdrop.in {
	opacity: 0.8;
}

.navbar-toggle{
	padding-top: 0px;
}

header .navbar-header {
	/*max-width: 250px;*/
	line-height: normal;
	margin-top: 10px;
	vertical-align: middle;
}

#terms-conditions h2,
#privacy-policies h2 {
	font-size: 24px;
	margin: 0;
	padding-bottom: 20px;
}

#terms-conditions .bordered,
#privacy-policies .bordered{
	padding: 40px;
}


.tab-option{
	width:50%;
}

.tab-option a {
	color: #5b5b5b;
}
.tab-option .active a {
	font-weight: 800;
}

.tab-panel .tab-content {
	margin-top:-5px;
}


.tab-panel {
	background-color:#F6F6F6;
}
.tab-panel .bordered {
	background-color: white;
}
.tab-panel hr {
	margin: 20px auto;
}

#register-tab .bordered{
	padding-bottom: 40px!important;
}

.tab-panel div[aria-controls="register-tab"]{

}
@media (-webkit-min-device-pixel-ratio:0)
{
	.form-control.currency {padding-left:calc(50% - 60px);}
	.form-control.language {padding-left: calc(50% - 35px);}
}

@-moz-document url-prefix() {
	.form-control.currency,
	.form-control.language {padding-left:0;}
}


@media (max-width: 768px){
	#background-beach{
		height: 500px;
	}

	#background-beach .description h2 {
		font-size:30px;
	}

	#background-beach .description {
		font-size:22px;
	}

	footer div.left, footer div.right {
		float: none!important;
		text-align: center;
	}

	footer div.right li,
	footer div.right ul{
		padding:0;
		margin:0;
		display:block;
	}


}
.carousel-control.right span{
	background-image:url(images/right-arrow.png)!important;
}

.carousel-control.left span{
	background-image:url(images/left-arrow.png)!important;
}
.carousel-control {
	background-repeat: no-repeat;
	background-size:contain;
	background-position: center center;
	width:35px;
	height:35px;
	display:block;
	top: calc(50% - 20px);
	position:absolute;
}

.carousel-control  span {
	display: block;
	width: 35px;
	height: 35px;
	background-size: contain;
}
@media (max-width: 767px){
	header a.main-logo {
		position: relative;
		top: 0px;
		left: 10%;
		right: 0;
	}


	header .navbar-header {
		max-width: 100%!important;
		margin:0!important;
		padding:0;
	}

	.navbar-toggle {
		margin:0;
		padding:0;
	}

	.logo-header {
		max-width: 200px;
		margin: 30px 0;
	}

	header {
		position: fixed;
		border-bottom: 1px solid white;
		background-color: #111;
		z-index: 2;
	}

	header a.main-logo span{
		padding: 10px;
	}

	header a.main-logo img {
		width: 70px;
	}

	.border-left {
		border-left: 0;
		padding:0;
	}
}

.videoWrapper {
	top: 45px;
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


/* This parent can be any width and height */
.block {
	text-align: center;
}

/* The ghost, nudged to maintain perfect centering */
.block:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	margin-right: -0.25em; /* Adjusts for spacing */
}

/* The element to be centered, can
also be of any width and height */
.centered {
	display: inline-block;
	vertical-align: middle;
	width: 300px;
}

.carousel-control.right,
.carousel-control.left{
	background-image: none!important;
}

.carousel .item {
	min-height: 500px;
}


.carousel .item .carousel-caption {
	text-shadow: none;
	color: inherit;
}

.checkbox label:after,
.radio label:after {
	content: '';
	display: table;
	clear: both;
}

.checkbox .cr,
.radio .cr {
	position: relative;
	display: inline-block;
	border: 1px solid #a9a9a9;
	border-radius: .25em;
	width: 1.3em;
	height: 1.3em;
	float: left;
}

.radio .cr {
	border-radius: 50%;
}

.radio, .checkbox{
	margin-bottom: 0;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
	position: absolute;
	font-size: .8em;
	line-height: 0;
	top: 50%;
	left: 20%;
}

.radio .cr .cr-icon {
	margin-left: 0.04em;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
	display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
	transform: scale(3) rotateZ(-20deg);
	opacity: 0;
	transition: all .3s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
	transform: scale(1) rotateZ(0deg);
	opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
	opacity: .5;
}

.checkbox i.destination-name{
	display: inline-block;
	width: 170px;
	vertical-align: top;
	white-space: pre-wrap;
	overflow: inherit;
}

.navbar-nav a {
	color:#fff!important;
}

.fb-linkto a{
	color: white;
	background-color: #4a63a9;
	padding: .9rem;
	display: block;
}

.link-right{
	line-height: 3;
	color:inherit;
}

.link-right:after{
	content: "\203A";

}

li.open a {
	background-color: transparent!important;
}


header.top .dropdown-menu  a{
	padding: 8px 15px;
	border-bottom: 1px solid white;
}

.dropdown-menu {
	width:100%;
	background-color: inherit!important;
	border:inherit!important;
	box-shadow: inherit!important;
	text-transform: uppercase;
}

header.top .dropdown-menu{
	background-color: #000!important;
	border: 1px solid white!important;
	margin-top: 10px;
	border-radius: 0!important;
	padding: 0;
}

.details-box {
	background-color: white;
	border: .1rem solid #dededf;
	margin: 5px;
	padding: 3rem 2rem;
	color: inherit;
	margin: 0.5rem .5rem 0 0;
	text-align: left;
}

.breadcrumb {
	background-color:#e0e0e0;
}

.breadcrumb a{
	color: #000;
	text-transform: uppercase;
}

.details-box h4{

}
.details-box a[data-role="change"] {
	border-radius: 0.2rem!important;
	cursor: pointer!important;
}


.details-box label {
	padding:0;
}


.details-box .input-group {
	display:block;
}

.details-box .form-control {
	width:100%;
	margin:0;
	margin-bottom: 10px;
	text-align: left;
}

.details-box a[data-role="change"], .details-box a[data-role="change"]:link, .details-box a[data-role="change"]:hover, .details-box a[data-role="change"]:active, .details-box a[data-role="change"]:visited, .details-box div[data-role="submit"], .details-box div[data-role="submit"]:link, .details-box div[data-role="submit"]:hover, .details-box div[data-role="submit"]:active, .details-box div[data-role="submit"]:visited, #avatar a[data-role="change"], #avatar div[data-role="submit"] {
	width: 100%;
	background-color: #5b5b5b;
	display: block;
	color: white;
	text-align: center;
	padding: 1rem 2rem;
	cursor: pointer;
	font-size: 1.6rem;
	border-radius: .2rem;
	text-transform: capitalize!important;
}

.subtitle h3{
	color:inherit;
	padding: 3rem;
	font-size: 2.8rem;
}



.change-avatar,
.avatar-thumb {
	height: 200px;
	width: 200px;
	background-position: center center;
	background-size: cover;
	border-radius: 100px;
	display: block;
	position: static;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	display: block;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	margin-bottom: 2rem;

}

.avatar-thumb {
	margin: 0!important;
	top: 0;
	vertical-align: text-top;
	padding: 0;
}

.change-avatar img,
.avatar-thumb img{
	display: inline;
	width: 100%;
	object-fit: cover;
	height: 200px;
	left: 0;
	right: 0;
	position: absolute;
}

.avatar-thumb img{
	height: inherit;
	margin: 0;
	bottom: 0;
}

.pick {
	display: block;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.4);
	color: white;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height:50px;
	padding: 0 0 6px;
	font-weight: 300;
}

#avatar input[type=file]{
	display:none;
}

.fit-in {
	background-position: center center;
	background-size: cover;
}

.tab-panel.modal-body {
	color:inherit;
}

.plans {
	flex-direction: column;
	/* float: left; */
	width: 33.3%;
	padding: 8px;
}

.plans .description {
	text-transform:uppercase;
	font-weight:600;
	font-size:20px;
	padding-top:30px!important;
}
.plans .information {
	list-style-type: none;
	border: 1px solid #eee;
	margin: 0;
	padding: 0;
	-webkit-transition: 0.3s;
	transition: 0.3s;
	min-height: 375px;
	height:auto;
	position:relative;
}

.plans .information:hover,
#most-popular .information{
	box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
}

.plans .information .header {
	color: white!important;
	font-size: 1.9rem;
	font-weight: 600;
	padding: 15px;
}

.plans .information li {
	padding: 15px 20px;
	text-align: center;
	color:#5b5b5b!important;
	font-size:1.8rem;
	font-weight: 600;
}

.plans .information li:last-of-type {
	margin-top: auto;
	position: absolute;
	bottom: 0;
	width: 100%;
}

.plans .checkbox label,
.plans .checkbox{
	margin:0;
	padding:0;
}

.plans .checkbox span.cr {
	color:#59c7c8!important;
}
.plans .price{
	font-size: 5rem!important;
	padding: 0!important;
}

.plans .price span:first-of-type{
	font-size: 2.2rem;
	vertical-align: middle;
}

.btn-payment {
	border: none;
	color: white;
	padding: 10px 25px;
	text-align: center;
	text-decoration: none;
	font-size: 18px;
	border-radius:2px;
}

.plans .header {
	position: relative;
	background: #88b7d5;
}
.plans .header:after {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(136, 183, 213, 0);
	border-top-color: #88b7d5;
	border-width: 18px;
	margin-left: -18px;
}

.plans .btn-payment {
	margin-top: 0!important;
	margin-bottom: 20px!important;
}

#most-popular .header,
#most-popular .btn-payment{
	background-color:#59c7c8!important;
}

#most-popular .header:after{
	border-top-color:#59c7c8;

}

#best-deals .header,
#best-deals .btn-payment{
	background-color:#fbcf63!important;
}

#best-deals .header:after{
	border-top-color:#fbcf63;
}

#standard li:nth-child(4){
	padding:10px 10px 10px 25px!important;
}

#standard .header,
#standard .btn-payment{
	background-color:#bbbbbb!important;
}

#standard .header:after{
	border-top-color:#bbbbbb;
}

#standard .information,
#best-deals .information{
	box-shadow: 0 1px 12px 0 rgba(0,0,0,0.2);
}

.modal-body .reactivation{
	color: gray;
}

.modal-body .reactivation a:not(.btn-continue) {
	color:inherit!important;
	font-size:inherit!important;
	font-weight: 600;
}
.reactivation .bordered {
	padding-top:60px;
	padding-bottom:60px;
}
.reactivation .uppercase {
	text-transform: uppercase;
}

.reactivation hr {
	margin-top: 15px;
	margin-bottom: 20px;
}

.reactivation img {
	display: block;
	margin: 0 auto;
	max-width: 75px;
	margin-bottom: 15px;
}

@media only screen and (max-width: 600px) {
	.plans{
		width: 100%;
	}
	#register-modal .modal-dialog,
	#message .modal-dialog {
		width: unset;
	}
}


#renew .bordered {
	margin:0;
}

#renew .tab-option {
	width: 100%;
	margin: 0;
	padding: 0;
}

#renew .tab-option a{
	margin: 0!important;
	border-radius: 0!important;
	text-align: center;
	font-weight: 600;
}

#renew hr {
	margin-bottom: 15px;
	margin-top: 15px;
}

#renew  p {
	color:gray;
}

#renew  .tab-content {
	font-size:1.6rem;
	color:#5b5b5b!important;
}

#expiration-modal .modal-body{
	padding: 5px;
	padding-bottom: 30px;
}

#expiration-modal .modal-body p{
	padding:30px 15px;
}

#expiration-modal .abuttons a{
	display: block;
	margin: 0;
}

#expiration-modal .modal-body {
	display:table;
}

#expiration-modal .tab-option {
	text-transform: uppercase;
	width: 100%;
	font-weight: 600;
	border-bottom: 1px solid #DADADA;
}

#expiration-modal .tab-option  a{
	border:none;
}

#expiration-modal .modal-body .plans p{
	padding:0;
	margin-top:30px;
}

#terms-conditions [class="text-justify"],
#privacy-policies [class="text-justify"]{
	overflow: scroll;
	height: 300px;
	padding-left:0;
}

#terms-conditions ol {
	list-style: none;
	margin:0;
}
#reset-modal .bordered,
*[data-role="submit"]{
	text-align: center!important;
}
#reset-modal h3{
	color: #04416D!important;
}

#linktofb button {
	display:none;
}

.alert-danger {
	margin-top: 20px;
}


@media(max-width: 992px){

	.navbar-header img:not(#hamburguer){
	    max-height: 21px !important;
		margin: 2.3rem 3rem !important;
	}
	.tab-content {
	    display: block !important;
	}

}

@media(max-width: 1200px){
	.navbar-header img:not(#hamburguer){
	    max-height: 21px !important;
		margin: 2.3rem 3rem !important;
	}
	.navbar-header{
		max-width: 100% !important;
	}
	.collapse.in{
		padding-top: 3rem;
		position: relative;
		z-index: 100;
	}
}
